import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { Wrapper } from '../components'
import { LocaleContext } from '../components/Layout'
import SEO from '../components/SEO'
import 'swiper/css/swiper.css';


import * as styles from "../styles/global.module.css"


const Hero = styled.header`
  background-color: var(--section-color);
  display: flex;
  align-items: center;
  height: 200px;
  position: relative;
  max-height: 900px;
`

const HeroInner = styled(Wrapper)`
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 2rem;
    z-index: 2;
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
`

const TextHero = styled.div`
  line-height: 1.4;
  margin-bottom: 2rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  max-width: 500px;
  left: 50%;
  margin-left: -250px;

  @media screen and (max-width: 39.9375em) {
    max-width: 320px;
    margin-left: -160px;
  }


  p {
    line-height: 1.3;
    font-size: 0.8rem;
  }

  h2 {
    font-size: 2.2rem;
    color: #fff;
  }
`

const globalStyle = css`
  :root {
    --section-color: #965f36;
    --swiper-theme-color: #965f36;
    --section-secondary-color: #352213;
  }
`

const ZonaWrapper = styled.main`
  .grid-container {
    max-width: 68rem;
  }
`

const Tour = ({  pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]


  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      <Global styles={globalStyle} />
      <Hero>
          <HeroInner className="grid-container">
            <TextHero>
              <h2>Tour Virtual</h2>
            </TextHero>
          </HeroInner>
      </Hero>
      <ZonaWrapper>
        <iframe 
            src="https://www.olozfera.com/naya_tourvirtual/"
            frameBorder="0" 
            style={{border: 0, width: '100%', height:'calc(100vh - 200px)'}} 
            allowfullscreen 
            ariaHidden="false" 
            tabindex="0"
        />
      </ZonaWrapper>
    </>
  )
}

export default Tour

